
.homeDisplay{
    color:white;
    font-size: 1.5rem;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    min-height: 60vh;
}

.aboutDisplay{
    color:white;
    font-size: 1.5rem;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    min-height: 70vh;
    text-align: left;
    width: 50%;
    padding-top: 25px;
}

.elementBase{
    margin: 0;
    padding: 0; 
    height: 100%;
    color: white;
    /* display: flex; */
}
.elementBase div{
    /* background-color: black; */
    margin: 0;
    padding: 10px; 
    height: 100%;
    color: white;
    /* display: flex; */
}

input{
    background-color: #1A1C1D;
    color: white;
    width: 30rem;
    text-align: center;
    border: 0;
    padding: 20px;
    border-bottom: 2px solid white;
}
input:focus {
    outline:none;
}

.displayedNames{
    padding: 25px 0;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    row-gap: 25px;
    column-gap: 5px;
}
.programNameDisplay{
    color:white;
}

.buttonSpace{
    padding: 35px;
    text-align: center;
    
}
.buttonSpaceOnOr{
    padding:10px;
}

.buttonGroupSpace{
    margin-top: 5px;
}


/* color input placeholder */

::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: white;
    opacity: 1; /* Firefox */
  }
  
  :-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: white;
  }
  
  ::-ms-input-placeholder { /* Microsoft Edge */
    color: white;
  }



  @media only screen and (max-width: 600px) {
    .homeDisplay{
        color:white;
        /* font-size: 1.1rem; */
        padding: 0, 5px;
        min-height: 50vh;
        word-wrap: break-word;
        width: 80vw;
        margin: auto;

    }
    
    .aboutDisplay{
        color:white;
        font-size: 1.2rem;
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        align-content: center;
        justify-content: center;
        min-height: 70vh;
        text-align: left;
        width: 80%;
        padding-top: 25px;
        padding-left: 5px;
        padding-right: 5px;
        margin: 0;
    }
    .displayedNames{
        padding: 25px 0;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        row-gap: 25px;
        column-gap: 5px;
    } 

    .elementBase{
        margin: 0;
        padding: 0; 
        height: 100%;
        color: white;
        width: 80%;
        /* display: flex; */
    }
    .elementBase div{
        /* background-color: black; */
        margin: 0;
        padding: 10px; 
        height: 100%;
        color: white;
        /* display: flex; */
    }


input{
    background-color: #1A1C1D;
    color: white;
    width: 90%;
    text-align: center;
    border: 0;
    padding: 20px;
    border-bottom: 2px solid white;
}
  }

  @media only screen and (min-width: 768px) {
    .homeDisplay{
        color:white;
        font-size: 1.5rem;
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        align-content: center;
        justify-content: center;
        min-height: 60vh;
    }
    
    .aboutDisplay{
        color:white;
        font-size: 1.5rem;
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        align-content: center;
        justify-content: center;
        min-height: 70vh;
        text-align: left;
        width: 80%;
        padding-top: 25px;
    }
    .displayedNames{
        padding: 25px 0;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        row-gap: 25px;
        column-gap: 5px;
    } 

  }