@import url('https://fonts.googleapis.com/css?family=Abril+Fatface|Barlow+Condensed:400,400i,500,500i,600,700,700i,800,800i,900|Roboto+Mono:300,400,400i,500,500i,700,700i&display=swap');
.App {
  text-align: center;
  margin: 0;
  background-color: #1A1C1D;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  margin: auto;
  padding-bottom: 35px;
  width: 100%;

}


/* font-family: 'Roboto Mono', monospace;
font-family: 'Barlow Condensed', sans-serif;
font-family: 'Abril Fatface', cursive; */